import TileLayer from 'ol/layer/Tile'
import OSMSource from 'ol/source/OSM'

export function createDefaultBaseLayer() {
  return new TileLayer({
    source: new OSMSource({
      url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    })
  })
}
