<template>
  <div
    class="file-drop-input d-flex justify-center align-center pa-2 rounded text--secondary"
    :class="{
      'flex-column': !dense,
      disabled: isInputDisabled,
      'text--disabled': isInputDisabled,
      'is-dragged-over': !isInputDisabled && isDraggedOver
    }"
    @dragenter="isDraggedOver = true"
    @dragover.prevent="isDraggedOver = true"
    @dragleave="isDraggedOver = false"
    @drop="isDraggedOver = false"
    @drop.prevent="handleFilesSelect"
  >
    <div class="text-center">
      <slot>Перетащите сюда файлы для их загрузки</slot>
    </div>

    <div
      class="file-drop-input__select-file text-decoration-underline"
      :class="{ 'ml-2': dense }"
      :tabindex="isInputDisabled ? undefined : 0"
      @keyup.enter="handleClick()"
      @click="handleClick()"
    >
      Выбрать...
    </div>

    <div v-if="flow.files.length" class="file-list mt-4">
      <v-sheet
        v-for="(file, i) of flow.files"
        :key="i"
        class="pa-2 overflow-hidden text-sm"
        :elevation="0"
        rounded
        dark
        :color="getFileElementColor(file)"
      >
        <div class="font-weight-bold text-truncate" :title="file.name">{{ file.name }}</div>
        <div class="mt-1">{{ formatFileSize(file.size) }}</div>
      </v-sheet>
    </div>

    <input ref="browseButton" class="d-none" type="file" multiple @change="handleFilesSelect" />
  </div>
</template>

<script>
  import { v4 as uuid } from 'uuid'
  import Flow from '@flowjs/flow.js'
  import { formatFileSize } from 'geoportal/src/utils/common'
  import { showErrorMessage } from '@/utils/messages'

  const defaultFlowSettings = {
    target: '/api/backend/storage/upload_chunk',
    maxChunkRetries: Infinity,
    chunkRetryInterval: 100,
    forceChunkSize: true,
    chunkSize: 5 * 1000 * 1000,
    permanentErrors: [400, 401, 404, 415, 500, 501]
  }

  const FileStatus = Object.freeze({
    DEFAULT: 'default',
    ERROR: 'error',
    COMPLETE: 'complete'
  })
  const fileStatusToColor = {
    [FileStatus.DEFAULT]: 'secondary',
    [FileStatus.ERROR]: 'error',
    [FileStatus.COMPLETE]: 'primary'
  }

  export default {
    name: 'FileDropInput',

    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      dense: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        isDraggedOver: false,

        flow: null,
        fileStatuses: {},
        storageId: null
      }
    },

    computed: {
      isInputDisabled() {
        return this.disabled
      },

      allFilesUploaded() {
        const fileStatuses = Object.values(this.fileStatuses)
        return !!fileStatuses.length && fileStatuses.every((fileStatus) => fileStatus === FileStatus.COMPLETE)
      }
    },

    created() {
      this.initFlow()
    },

    methods: {
      formatFileSize,

      initFlow() {
        this.flow = new Flow({
          ...defaultFlowSettings,
          query: this.getFileQueryParameters
        })

        this.flow.on('fileAdded', async (flowFile) => {
          this.$set(this.fileStatuses, flowFile.uniqueIdentifier, FileStatus.DEFAULT)

          await this.$nextTick()
          this.flow.upload()
        })

        this.flow.on('fileError', async (flowFile, message) => {
          this.$set(this.fileStatuses, flowFile.uniqueIdentifier, FileStatus.ERROR)

          try {
            message = JSON.parse(message).message
          } catch {}

          showErrorMessage(`При загрузке файла произошла ошибка: ${message}`)
        })

        this.flow.on('fileSuccess', (flowFile) => {
          this.$set(this.fileStatuses, flowFile.uniqueIdentifier, FileStatus.COMPLETE)
        })
      },

      getFileQueryParameters() {
        if (!this.storageId) {
          this.storageId = uuid()
          this.$emit('input', this.storageId)
        }

        return {
          storage_id: this.storageId
        }
      },

      handleClick() {
        if (this.isInputDisabled) {
          return
        }

        this.$refs.browseButton.click()
      },

      handleFilesSelect(event) {
        if (this.isInputDisabled) {
          return
        }

        const files = event.target.files || event.dataTransfer.files
        this.flow.addFiles([...files])
      },

      getFileElementColor(flowFile) {
        const status = this.fileStatuses[flowFile.uniqueIdentifier]
        return fileStatusToColor[status]
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/styles/variables.scss';

  .file-drop-input {
    border: 2px dashed map-get($grey, 'lighten-1');
    transition: $primary-transition;

    &.disabled {
      cursor: not-allowed;
      border: 2px dashed map-get($grey, 'lighten-2');
    }

    &:not(.disabled) {
      &.is-dragged-over {
        border: 2px dashed map-get($grey, 'darken-2');
        background-color: rgba(map-get($grey, 'darken-2'), 0.12);
      }

      .file-drop-input__select-file {
        color: map-deep-get($material-theme, 'text', 'secondary');
        cursor: pointer;

        &:focus {
          outline: none;
        }

        &:hover,
        &:focus {
          color: map-get($theme-colors, 'primary');
        }
      }
    }

    .file-list {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 8px;
    }
  }
</style>
