var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{directives:[{name:"ripple",rawName:"v-ripple",value:(_vm.isFinished),expression:"isFinished"}],staticClass:"layer-version-card pa-3",class:{
    'layer-version-card_selectable': _vm.isFinished,
    'layer-version-card_visible': _vm.isVisible,
    'text--muted': !_vm.isFinished
  },attrs:{"elevation":0},on:{"click":function($event){_vm.isFinished ? _vm.setVisible() : undefined}}},[_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v(_vm._s(_vm.createdDatetime))]),_c('v-spacer'),_c('v-menu',{attrs:{"left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.isPending,"loading":_vm.isPending,"icon":"","small":"","dark":_vm.isVisible}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.$vuetify.icons.values.menu))])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.menuItems),function(item){return _c('v-list-item',{key:item.text,attrs:{"disabled":item.disabled},on:{"click":item.handler}},[_vm._v(" "+_vm._s(item.text)+" ")])}),1)],1)],1),_c('div',{staticClass:"d-flex mt-4"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.value.name))]),_c('v-spacer'),(_vm.status)?_c('v-sheet',{staticClass:"status-card ml-2 px-3 py-2 flex-shrink-0 align-self-start d-flex justify-center align-center text-center font-weight-bold",class:_vm.status.class,attrs:{"title":_vm.value.error,"rounded":""}},[_vm._v(" "+_vm._s(_vm.status.text)+" ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }