var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"absolute":"","small":"","color":"secondary"}},'v-btn',attrs,false),on),[_vm._v("Журнал действий")])]}}]),model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Журнал действий "),_c('v-btn',{staticClass:"ml-auto",attrs:{"small":"","icon":""},on:{"click":function($event){_vm.isActive = false}}},[_c('v-icon',[_vm._v(_vm._s(_vm.$vuetify.icons.values.close))])],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.tableItems,"headers":_vm.tableConfiguration,"options":_vm.tableSettings,"footer-props":{ itemsPerPageOptions: [15, 30, 50, 100] },"loading":_vm.isPending,"multi-sort":"","server-items-length":_vm.tableItemsCount},on:{"update:options":function($event){_vm.tableSettings=$event}},scopedSlots:_vm._u([{key:"item.datetime",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.isoStringToDisplayDateTime(value))+" ")]}},{key:"item.basin",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t(("aquarius.basin." + value)))+" ")]}},{key:"item.action",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t(("aquarius.layerLogAction." + value)))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }