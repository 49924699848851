<template>
  <v-dialog v-model="isActive" width="600">
    <v-card>
      <v-card-title>
        Новая версия слоя

        <v-spacer />

        <v-btn small icon @click="isActive = false">
          <v-icon>{{ $vuetify.icons.values.close }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="mt-8">
        <base-form v-if="isActive" ref="form" v-model="formModel" :configuration="formConfiguration" />

        <div class="mt-8 d-flex justify-end">
          <v-btn :disabled="isPending" text color="primary" @click="isActive = false">Отменить</v-btn>

          <v-btn :disabled="isPending" :loading="isPending" text color="primary" @click="handleCreateLayerVersion()">
            Создать
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { get, call } from 'vuex-pathify'
  import PendingTasksManagerMixin, { handlePendingTask } from 'geoportal/src/mixins/PendingTasksManagerMixin'
  import { requiredPropertyRule } from '@/utils/forms'
  import { createFormConfiguration, formFieldPresets } from '@/components/BaseForm/formHelpers'
  import FileDropInput from '@/components/FileDropInput'

  const formConfiguration = createFormConfiguration([
    [
      {
        id: 'storage_id',
        cmp: FileDropInput
      }
    ],
    [
      {
        id: 'name',
        label: 'Название',
        required: true,
        bind: {
          filled: false,
          rules: [requiredPropertyRule]
        }
      },
      formFieldPresets.text
    ]
  ])

  export default {
    name: 'CreateLayerVersionDialog',
    mixins: [PendingTasksManagerMixin],

    data() {
      return {
        isActive: false,

        formConfiguration,
        formModel: {}
      }
    },

    computed: {
      ...get('layersManagement', {
        selectedLayerId: 'selectedLayerId'
      })
    },

    watch: {
      isActive() {
        if (!this.isActive) {
          this.formModel = {}
        }
      }
    },

    methods: {
      ...call('layersManagement', {
        createLayerVersion: 'createLayerVersion'
      }),

      initDialog() {
        this.isActive = true
      },

      @handlePendingTask()
      async handleCreateLayerVersion() {
        if (!this.$refs.form.validate()) {
          return
        }

        const fileInputCmp = this.$refs.form.$refs['field.storage_id'][0].$refs.input
        if (!fileInputCmp.allFilesUploaded) {
          return
        }

        await this.createLayerVersion({
          ...this.formModel,
          layer_id: this.selectedLayerId
        })

        this.isActive = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .base-form {
    &::v-deep {
      .file-drop-input {
        margin-bottom: 16px;
      }
    }
  }
</style>
