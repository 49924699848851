<template>
  <v-dialog v-model="isActive" fullscreen>
    <template #activator="{ on, attrs }">
      <v-btn absolute small color="secondary" v-bind="attrs" v-on="on">Журнал действий</v-btn>
    </template>

    <v-card>
      <v-card-title>
        Журнал действий

        <v-btn class="ml-auto" small icon @click="isActive = false">
          <v-icon>{{ $vuetify.icons.values.close }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :items="tableItems"
          :headers="tableConfiguration"
          :options.sync="tableSettings"
          :footer-props="{ itemsPerPageOptions: [15, 30, 50, 100] }"
          :loading="isPending"
          multi-sort
          :server-items-length="tableItemsCount"
        >
          <template #item.datetime="{ value }">
            {{ isoStringToDisplayDateTime(value) }}
          </template>
          <template #item.basin="{ value }">
            {{ $t(`aquarius.basin.${value}`) }}
          </template>
          <template #item.action="{ value }">
            {{ $t(`aquarius.layerLogAction.${value}`) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import _ from 'lodash'
  import { isoStringToDisplayDateTime } from 'geoportal/src/utils/time'
  import PendingTasksManagerMixin, { handlePendingTask } from 'geoportal/src/mixins/PendingTasksManagerMixin'
  import { tableSettingsToRequestParameters } from '@/utils/tables'

  const tableConfiguration = [
    { value: 'login', text: 'Логин' },
    { value: 'datetime', text: 'Дата' },
    { value: 'basin', text: 'РХБ' },
    { value: 'layer', text: 'Слой' },
    { value: 'layer_version', text: 'Версия' },
    { value: 'action', text: 'Действие' }
  ]
  const defaultTableSettings = {
    page: 1,
    itemsPerPage: 15,
    multiSort: true,
    sortBy: ['datetime'],
    sortDesc: [true]
  }

  export default {
    name: 'LogDialog',
    mixins: [PendingTasksManagerMixin],

    data() {
      return {
        isActive: false,

        tableConfiguration,
        tableSettings: _.cloneDeep(defaultTableSettings),
        tableItemsCount: 0,
        tableItems: []
      }
    },

    computed: {
      fetchTableItemsDebounced() {
        return _.debounce(this.fetchTableItems, 100)
      }
    },

    watch: {
      tableSettings: 'fetchTableItemsDebounced',

      isActive() {
        this.isActive && this.fetchTableItemsDebounced()
      }
    },

    methods: {
      isoStringToDisplayDateTime,

      @handlePendingTask()
      async fetchTableItems() {
        const requestData = tableSettingsToRequestParameters(this.tableSettings)
        const result = await this.$api.backend.layers.get_log(requestData)
        this.tableItemsCount = result.count
        this.tableItems = result.items
      }
    }
  }
</script>

<!--<style lang="scss" scoped></style>-->
