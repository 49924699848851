<template>
  <v-sheet
    v-ripple="isFinished"
    class="layer-version-card pa-3"
    :class="{
      'layer-version-card_selectable': isFinished,
      'layer-version-card_visible': isVisible,
      'text--muted': !isFinished
    }"
    :elevation="0"
    @click="isFinished ? setVisible() : undefined"
  >
    <div class="d-flex">
      <div>{{ createdDatetime }}</div>

      <v-spacer />

      <v-menu left offset-y>
        <template #activator="{ on, attrs }">
          <v-btn :disabled="isPending" :loading="isPending" icon small :dark="isVisible" v-bind="attrs" v-on="on">
            <v-icon>{{ $vuetify.icons.values.menu }}</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item v-for="item of menuItems" :key="item.text" :disabled="item.disabled" @click="item.handler">
            {{ item.text }}
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <div class="d-flex mt-4">
      <div class="font-weight-bold">{{ value.name }}</div>

      <v-spacer />

      <v-sheet
        v-if="status"
        class="status-card ml-2 px-3 py-2 flex-shrink-0 align-self-start d-flex justify-center align-center text-center font-weight-bold"
        :class="status.class"
        :title="value.error"
        rounded
      >
        {{ status.text }}
      </v-sheet>
    </div>
  </v-sheet>
</template>

<script>
  import { get, call, sync } from 'vuex-pathify'
  import { createDownloadableFile } from 'geoportal/src/utils/common'
  import { isoStringToDisplayDate } from 'geoportal/src/utils/time'
  import PendingTasksManagerMixin, { handlePendingTask } from 'geoportal/src/mixins/PendingTasksManagerMixin'
  import { AccountType, LayerType, LayerVersionStatus } from '@/enums/aquarius'

  export default {
    name: 'LayerVersionCard',
    mixins: [PendingTasksManagerMixin],

    props: {
      value: {
        type: Object,
        required: true
      }
    },

    computed: {
      ...sync('layersManagement', {
        visibleLayerVersionId: 'visibleLayerVersionId'
      }),
      ...get('layersManagement', {
        selectedLayer: 'selectedLayer'
      }),

      isAdmin() {
        return this.$auth.user.account_type === AccountType.ADMIN
      },

      createdDatetime() {
        return isoStringToDisplayDate(this.value.created_datetime)
      },

      isActiveVersion() {
        return this.value.id === this.selectedLayer.active_version_id
      },

      isFinished() {
        return this.value.processing_status === LayerVersionStatus.FINISHED
      },

      isVisible() {
        return this.visibleLayerVersionId === this.value.id
      },

      status() {
        if (this.value.processing_status === LayerVersionStatus.ERROR) {
          return {
            text: 'Ошибка',
            class: 'status-card__error'
          }
        } else if (this.value.processing_status === LayerVersionStatus.RUNNING) {
          return {
            text: 'Проверка файлов',
            class: 'status-card__running'
          }
        } else if (this.isActiveVersion) {
          return {
            // activated_datetime could be missing if the version was activated just now for the first time
            text: this.value.activated_datetime
              ? `Применена с ${isoStringToDisplayDate(this.value.activated_datetime)}`
              : 'Применена',
            class: 'status-card__active'
          }
        } else if (this.value.deactivated_datetime) {
          return {
            text: `Отменена с ${isoStringToDisplayDate(this.value.deactivated_datetime)}`,
            class: 'status-card__inactive'
          }
        }

        return null
      },

      menuItems() {
        const items = []

        const processingStatus = this.value.processing_status

        if (this.isActiveVersion) {
          items.push({
            text: 'Отменить версию',
            disabled: !this.isAdmin || this.value.type !== LayerType.ADDITIONAL,
            handler: this.deactivateVersion
          })
        } else {
          items.push({
            text: 'Применить версию',
            disabled: !this.isAdmin || processingStatus !== LayerVersionStatus.FINISHED,
            handler: this.activateVersion
          })
        }

        items.push({
          text: 'Переименовать',
          disabled: !this.isAdmin || processingStatus === LayerVersionStatus.ERROR,
          handler: this.renameVersion
        })

        items.push({
          text: 'Скачать',
          disabled: !this.isAdmin || processingStatus === LayerVersionStatus.RUNNING,
          handler: this.downloadVersionFiles
        })

        if (processingStatus !== LayerVersionStatus.FINISHED) {
          items.push({
            text: 'Удалить',
            disabled: !this.isAdmin,
            handler: this.deleteVersion
          })
        }

        return items
      }
    },

    methods: {
      ...call('layersManagement', {
        updateLayer: 'updateLayer',
        fetchLayerVersions: 'fetchLayerVersions',
        updateLayerVersion: 'updateLayerVersion',
        deleteLayerVersion: 'deleteLayerVersion'
      }),

      setVisible() {
        this.visibleLayerVersionId = this.visibleLayerVersionId === this.value.id ? null : this.value.id
      },

      @handlePendingTask()
      async activateVersion() {
        await this.updateLayer({
          id: this.selectedLayer.id,
          data: { active_version_id: this.value.id }
        })
        this.fetchLayerVersions()
      },

      @handlePendingTask()
      async deactivateVersion() {
        await this.updateLayer({
          id: this.selectedLayer.id,
          data: { active_version_id: null }
        })
        this.fetchLayerVersions()
      },

      renameVersion() {
        this.$bus.emit('layersManagement/renameLayerVersion', this.value.id)
      },

      downloadVersionFiles() {
        createDownloadableFile(
          `/api/backend/layers/get_original_files?version_id=${this.value.id}`,
          `${this.value.name}.zip`
        )
      },

      @handlePendingTask()
      async deleteVersion() {
        await this.deleteLayerVersion(this.value.id)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/styles/variables.scss';

  .layer-version-card {
    .status-card {
      width: 150px;

      &.status-card__error {
        color: map-get($guide-colors, 'secondary-4');
        background: rgba(map-get($guide-colors, 'secondary-4'), 0.12);
      }

      &.status-card__running {
        color: map-get($guide-colors, 'accent-2');
        background: rgba(map-get($guide-colors, 'accent-2'), 0.12);
      }

      &.status-card__active {
        color: map-get($guide-colors, 'primary-1');
        background: rgba(map-get($guide-colors, 'primary-1'), 0.12);
      }

      &.status-card__inactive {
        color: map-get($guide-colors, 'secondary-1');
        background: rgba(map-get($guide-colors, 'secondary-1'), 0.12);
      }
    }

    &.layer-version-card_selectable {
      cursor: pointer;
    }

    &.layer-version-card_visible {
      color: white;
      background: map-get($guide-colors, 'primary-1');

      .status-card {
        color: white;
        background: rgba(white, 0.12);
      }
    }
  }
</style>
