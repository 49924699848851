<template>
  <div class="layers-list mb-5">
    <div class="d-flex align-center pa-5 text-xl font-weight-bold">
      <v-btn icon small @click="goBack()">
        <v-icon>{{ $vuetify.icons.values.goBack }}</v-icon>
      </v-btn>

      <span class="ml-3">{{ $t(`aquarius.basin.${selectedBasin}`) }}</span>
    </div>

    <v-list class="mt-3 pa-0 font-weight-bold" color="transparent">
      <v-list-item-group
        v-for="layersGroup of ['required', 'additional']"
        :key="layersGroup"
        @change="handleLayerSelect"
      >
        <v-subheader class="font-weight-bold align-center">
          {{ $t(`layersManagement.layersGroup.${layersGroup}`) }}

          <template v-if="layersGroup === 'additional'">
            <v-spacer />

            <v-btn icon @click="handleCreateLayer()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
        </v-subheader>

        <draggable
          :value="groupedLayers[layersGroup]"
          :group="{ name: layersGroup, pull: false, put: false }"
          @input="handleLayerOrderChange(layersGroup, $event)"
        >
          <!-- disable ripple because it causes a list item to look weird while dragging -->
          <v-list-item v-for="layer of groupedLayers[layersGroup]" :key="layer.id" :value="layer.id" :ripple="false">
            <v-list-item-icon>
              <v-icon v-if="layer.active_version_id" color="primary">
                {{ layer.visible ? 'mdi-eye-check' : 'mdi-check-bold' }}
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ layer.name }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </draggable>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
  import { get, call, sync } from 'vuex-pathify'
  import draggable from 'vuedraggable'
  import { sortByProperty } from 'geoportal/src/utils/func'
  import { LayerType } from '@/enums/aquarius'
  import { AsidePanelState } from '@/views/LayersManagementView/layersManagement'

  export default {
    name: 'LayersList',
    components: { draggable },

    computed: {
      ...get('layersManagement', {
        layers: 'layers'
      }),
      ...sync('layersManagement', {
        selectedBasin: 'selectedBasin'
      }),

      groupedLayers() {
        const result = this.layers.reduce(
          (acc, layer) => {
            const key = layer.type === LayerType.ADDITIONAL ? 'additional' : 'required'
            acc[key].push(layer)

            return acc
          },
          { required: [], additional: [] }
        )
        Object.values(result).forEach((layers) => sortByProperty(layers, 'order'))
        return result
      }
    },

    methods: {
      ...call('layersManagement', {
        setAsidePanelState: 'setAsidePanelState',
        setLayersOrder: 'setLayersOrder'
      }),

      goBack() {
        this.setAsidePanelState([AsidePanelState.BASIN_SELECTION])
      },

      handleLayerOrderChange(layersGroup, layers) {
        const layersIds = layers.map((layer) => layer.id)
        this.setLayersOrder({ layersGroup, layersIds })
      },

      handleLayerSelect(layerId) {
        this.setAsidePanelState([AsidePanelState.LAYER, layerId])
      },

      handleCreateLayer() {
        this.setAsidePanelState([AsidePanelState.CREATE_ADDITIONAL_LAYER])
      }
    }
  }
</script>

<!--<style lang="scss" scoped></style>-->
