<template>
  <v-sheet class="aside-panel overflow-y-scroll thin-scrollbar" tag="aside" color="transparent">
    <basin-selection v-if="asidePanelState === AsidePanelState.BASIN_SELECTION" />
    <layers-list v-else-if="asidePanelState === AsidePanelState.LAYER_LIST" />
    <layer v-else-if="asidePanelState === AsidePanelState.LAYER" />
    <create-additional-layer v-else-if="asidePanelState === AsidePanelState.CREATE_ADDITIONAL_LAYER" />

    <rename-dialog />
  </v-sheet>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { AsidePanelState } from '../layersManagement'
  import BasinSelection from './BasinSelection'
  import LayersList from './LayersList/LayersList'
  import Layer from './Layer/Layer'
  import RenameDialog from './RenameDialog'
  import CreateAdditionalLayer from './CreateAdditionalLayer'

  export default {
    name: 'AsidePanel',
    components: { BasinSelection, LayersList, Layer, RenameDialog, CreateAdditionalLayer },

    data() {
      return {
        AsidePanelState
      }
    },

    computed: {
      ...get('layersManagement', {
        asidePanelState: 'asidePanelState'
      })
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/styles/variables.scss';

  .aside-panel {
    width: 400px;
    height: 100%;
    box-shadow: -2px 6px 9px rgba(0, 0, 0, 0.25);

    &::v-deep {
      .v-list-item-group {
        &:not(:first-child) {
          margin-top: 60px;
        }
      }

      .v-list-item {
        background: white;

        &::before {
          background: map-get($guide-colors, 'primary-1');
        }

        &:hover::before {
          opacity: 0.12;
        }
      }
    }
  }
</style>
