import _ from 'lodash'

import { createQueryString } from './common'

/**
 * @param {string} fullLayerID
 * @returns {{layerNamespace: string, layerID: string}}
 */
export function parseFullLayerID(fullLayerID) {
  const [layerNamespace, layerID] = fullLayerID.split(':')
  return { layerNamespace, layerID }
}

/**
 * @param {string} layerNamespace
 * @param {string} layerID
 * @returns {string}
 */
export function getFullLayerID(layerNamespace, layerID) {
  return `${layerNamespace}:${layerID}`
}

/**
 * Returns feature number from its GeoServer ID
 * @example
 * getFeatureNumberFromID('d03cdf2685bc_shapefile.46')  // 46
 * @param {string} featureID
 * @returns {number}
 */
export function getFeatureNumberFromID(featureID) {
  return parseInt(_.last(featureID.split('.')), 10)
}

/**
 * @see http://docs.geoserver.org/stable/en/user/services/wfs/reference.html#getfeature
 * @param {Object} params
 * @param {string} params.geoServerURL
 * @param {Array|string|null} [params.typeNames]
 * @param {string|null} [params.featureID]
 * @param {number|null} [params.count]
 * @param {Array|string|null} [params.bbox]
 * @param {Object|string|null} [params.filter]
 * @returns {string}
 */
export function getGetFeatureURL({
  geoServerURL,
  typeNames = null,
  featureID = null,
  count = null,
  bbox = null,
  filter = null
}) {
  const params = {
    service: 'WFS',
    version: '2.0.0',
    outputFormat: 'application/json',
    request: 'GetFeature'
  }

  if (typeNames) {
    params.typeNames = Array.isArray(typeNames) ? typeNames.join(',') : typeNames
  }

  if (featureID) {
    params.featureID = Array.isArray(featureID) ? featureID.join(',') : featureID
  }

  if (count) {
    params.count = count
  }

  if (bbox) {
    params.bbox = Array.isArray(bbox) ? bbox.join(',') : bbox
  }

  if (filter) {
    params.cql_filter = filter
  }

  return `${geoServerURL}?${createQueryString(params)}`
}
