<template>
  <div ref="mapContainer" class="map-container">
    <slot />
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import Map from 'ol/Map'
  import View from 'ol/View'
  import TileLayer from 'ol/layer/Tile'
  import TileWMSSource from 'ol/source/TileWMS'
  import WMSServerType from 'ol/source/WMSServerType'
  import Zoom from 'ol/control/Zoom'
  import MousePosition from 'ol/control/MousePosition'
  import ScaleLine from 'ol/control/ScaleLine'
  import Attribution from 'ol/control/Attribution'
  import { EPSG_4326, transformCoordinateToEpsg4326 } from 'geoportal/src/utils/geo'
  import { getFullLayerID } from 'geoportal/src/utils/geoServer'
  import settings from '@/settings'
  import { LayerType } from '@/enums/aquarius'
  import { LAYER_NAMESPACE } from '@/configurations/aquarius'
  import { createDefaultBaseLayer } from '@/utils/map'

  const VISIBLE_LAYER_VERSION_LAYER_ID = 'visible_layer_version_layer'

  export default {
    name: 'MapContainer',

    computed: {
      ...get('layersManagement', {
        selectedLayer: 'selectedLayer',
        visibleLayerVersion: 'visibleLayerVersion'
      })
    },

    watch: {
      visibleLayerVersion: {
        handler: 'handleLayerVersionChange',
        deep: true
      }
    },

    created() {
      // static data
      this.map = null
      this.versionLayer = null
    },

    mounted() {
      this.setupMap()
    },

    methods: {
      setupMap() {
        this.map = new Map({
          target: this.$refs.mapContainer,
          view: new View({
            center: [0, 0],
            zoom: 2,
            enableRotation: false
          }),
          layers: [createDefaultBaseLayer()]
        })

        this.setupControls()

        this.handleLayerVersionChange()
      },

      setupControls() {
        const controls = this.map.getControls()
        controls.clear()

        const zoomInLabel = document.createElement('span')
        zoomInLabel.classList.add('v-icon', 'mdi', 'mdi-plus')
        const zoomOutLabel = document.createElement('span')
        zoomOutLabel.classList.add('v-icon', 'mdi', 'mdi-minus')
        controls.push(
          new Zoom({
            zoomInLabel,
            zoomInTipLabel: '',
            zoomOutLabel,
            zoomOutTipLabel: ''
          })
        )

        controls.push(
          new MousePosition({
            coordinateFormat: (coordinate) =>
              transformCoordinateToEpsg4326(coordinate)
                .map((value) => `${value.toFixed(6)}°`)
                .reverse()
                .join(' '),
            projection: EPSG_4326,
            undefinedHTML: ''
          })
        )

        controls.push(new ScaleLine())

        controls.push(new Attribution())
      },

      handleLayerVersionChange() {
        if (this.versionLayer) {
          this.map.removeLayer(this.versionLayer)
          this.versionLayer = null
        }

        if (this.visibleLayerVersion) {
          this.versionLayer = new TileLayer({
            id: VISIBLE_LAYER_VERSION_LAYER_ID,
            source: new TileWMSSource({
              url: `${settings.urls.geoServer}/${LAYER_NAMESPACE}/wms`,
              params: {
                LAYERS: getFullLayerID(LAYER_NAMESPACE, this.visibleLayerVersion.geoserver_layer_name),
                TILED: true,
                STYLES: this.selectedLayer.type === LayerType.ADDITIONAL ? undefined : this.selectedLayer.type
              },
              serverType: WMSServerType.GEOSERVER
            })
          })
          this.map.addLayer(this.versionLayer)
          // TODO: zoom to layer?
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .map-container {
    &::v-deep {
      .ol-zoom {
        left: unset;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
      }

      .ol-scale-line {
        left: unset;
        right: 8px;
        bottom: 28px;
      }

      .ol-mouse-position {
        top: unset;
        bottom: 58px;
        padding: 2px 8px;
        border-radius: 4px;
        font-size: 0.875rem;
        background: rgba(255, 255, 255, 0.8);
      }

      .ol-attribution {
        font-size: 0.75rem;
      }
    }
  }
</style>
