<template>
  <v-dialog v-model="isActive" width="600">
    <v-card>
      <v-card-title>
        {{ title }}

        <v-spacer />

        <v-btn small icon @click="isActive = false">
          <v-icon>{{ $vuetify.icons.values.close }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="mt-8">
        <base-form ref="form" v-model="formModel" :configuration="formConfiguration" />

        <div class="mt-8 d-flex justify-end">
          <v-btn :disabled="isPending" :loading="isPending" text color="primary" @click="save()">Переименовать</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { get, call } from 'vuex-pathify'
  import { isoStringToDisplayDate } from 'geoportal/src/utils/time'
  import PendingTasksManagerMixin, { handlePendingTask } from 'geoportal/src/mixins/PendingTasksManagerMixin'
  import { showMessage } from '@/utils/messages'
  import { requiredPropertyRule } from '@/utils/forms'
  import { createFormConfiguration, formFieldPresets } from '@/components/BaseForm/formHelpers'

  const formConfiguration = createFormConfiguration([
    [
      {
        id: 'name',
        label: 'Название',
        required: true,
        bind: {
          filled: false,
          autofocus: true,
          rules: [requiredPropertyRule]
        }
      },
      formFieldPresets.text
    ]
  ])

  export default {
    name: 'RenameDialog',
    mixins: [PendingTasksManagerMixin],

    data() {
      return {
        isActive: false,

        formConfiguration,
        formModel: {},

        layerId: null,
        layerVersionId: null
      }
    },

    computed: {
      ...get('layersManagement', {
        layersIndex: 'layersIndex',
        layersVersionsIndex: 'layersVersionsIndex'
      }),

      layer() {
        return this.layersIndex[this.layerId]
      },

      layerVersion() {
        return this.layersVersionsIndex[this.layerVersionId]
      },

      title() {
        if (this.layerId) {
          return 'Переименование дополнительного слоя'
        } else if (this.layerVersionId) {
          return `Переименование версии слоя от ${isoStringToDisplayDate(this.layerVersion.created_datetime)}`
        }

        return null
      }
    },

    watch: {
      isActive() {
        if (!this.isActive) {
          this.formModel = {}
          this.layerId = null
          this.layerVersionId = null
        }
      }
    },

    created() {
      this.$bus.listen(this, 'layersManagement/renameLayer', (layerId) => {
        this.layerId = layerId
        this.formModel = { name: this.layer.name }
        this.isActive = true
      })
      this.$bus.listen(this, 'layersManagement/renameLayerVersion', (layerVersionId) => {
        this.layerVersionId = layerVersionId
        this.formModel = { name: this.layerVersion.name }
        this.isActive = true
      })
    },

    methods: {
      ...call('layersManagement', {
        updateLayer: 'updateLayer',
        updateLayerVersion: 'updateLayerVersion'
      }),

      @handlePendingTask()
      async save() {
        if (!this.$refs.form.validate()) {
          return
        }

        if (this.layerId) {
          await this.updateLayer({
            id: this.layerId,
            data: this.formModel
          })
          showMessage('Слой переименован')
        } else if (this.layerVersionId) {
          await this.updateLayerVersion({
            id: this.layerVersionId,
            data: this.formModel
          })
          showMessage('Версия слоя переименована')
        }

        this.isActive = false
      }
    }
  }
</script>

<!--<style lang="scss" scoped></style>-->
