<template>
  <div class="pa-5">
    <div class="d-flex align-center text-xl font-weight-bold">
      <v-btn icon small @click="goBack()">
        <v-icon>{{ $vuetify.icons.values.goBack }}</v-icon>
      </v-btn>

      <span class="ml-3">Новый дополнительный слой</span>
    </div>

    <base-form ref="form" v-model="formModel" class="mt-8" :disabled="isPending" :configuration="formConfiguration" />

    <v-btn
      class="mt-5 w-100"
      :disabled="isPending"
      :loading="isPending"
      small
      depressed
      color="primary"
      @click="handleCreateLayer()"
    >
      Создать
    </v-btn>
  </div>
</template>

<script>
  import { get, call } from 'vuex-pathify'
  import PendingTasksManagerMixin, { handlePendingTask } from 'geoportal/src/mixins/PendingTasksManagerMixin'
  import { requiredPropertyRule } from '@/utils/forms'
  import { AsidePanelState } from '../layersManagement'
  import { createFormConfiguration, formFieldPresets } from '@/components/BaseForm/formHelpers'
  import { showMessage } from '@/utils/messages'

  const formConfiguration = createFormConfiguration([
    [
      { id: 'name', label: 'Название', required: true, bind: { filled: false, rules: [requiredPropertyRule] } },
      formFieldPresets.text
    ]
  ])

  export default {
    name: 'CreateAdditionalLayer',
    mixins: [PendingTasksManagerMixin],

    data() {
      return {
        formModel: {},
        formConfiguration
      }
    },

    computed: {
      ...get('layersManagement', {
        selectedBasin: 'selectedBasin'
      })
    },

    methods: {
      ...call('layersManagement', {
        setAsidePanelState: 'setAsidePanelState',
        createAdditionalLayer: 'createAdditionalLayer'
      }),

      goBack() {
        this.setAsidePanelState([AsidePanelState.LAYER_LIST])
      },

      @handlePendingTask()
      async handleCreateLayer() {
        if (!this.$refs.form.validate()) {
          return
        }

        await this.createAdditionalLayer({
          ...this.formModel,
          basin: this.selectedBasin
        })

        showMessage('Дополнительный слой успешно создан')

        this.goBack()
      }
    }
  }
</script>

<!--<style lang="scss" scoped></style>-->
