<template>
  <div>
    <div class="pa-5 text-xl font-weight-bold">РХБ</div>

    <v-list class="mt-3 pa-0 font-weight-bold">
      <v-list-item-group @change="handleBasinSelect">
        <v-list-item v-for="basin of basins" :key="basin" :value="basin">
          <v-list-item-icon />
          <v-list-item-content>
            <v-list-item-title>{{ $t(`aquarius.basinShortName.${basin}`) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
  import { call, sync } from 'vuex-pathify'
  import { Basin } from '@/enums/aquarius'
  import { AsidePanelState } from '../layersManagement'

  export default {
    name: 'BasinSelection',

    computed: {
      ...sync('layersManagement', {
        selectedBasin: 'selectedBasin'
      }),

      basins() {
        return [Basin.FAR_EAST, Basin.NORTH].map((basin) => basin)
      }
    },

    methods: {
      ...call('layersManagement', {
        setAsidePanelState: 'setAsidePanelState'
      }),

      handleBasinSelect(basin) {
        this.setAsidePanelState([AsidePanelState.LAYER_LIST, basin])
      }
    }
  }
</script>

<!--<style lang="scss" scoped></style>-->
