import _ from 'lodash'

export function tableSettingsToRequestParameters(tableSettings) {
  const { sortBy, sortDesc, page, itemsPerPage } = tableSettings

  const sorting = _.zip(sortBy, sortDesc).map(([parameter, sortOrder]) => ({
    parameter,
    sort_order: sortOrder ? 'desc' : 'asc'
  }))

  return {
    sorting: sorting.length ? sorting : undefined,
    offset: (page - 1) * itemsPerPage,
    limit: itemsPerPage
  }
}
