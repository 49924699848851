<template>
  <div v-if="selectedLayer" class="layer d-flex flex-column">
    <div class="d-flex align-center pa-5 text-xl font-weight-bold">
      <v-btn icon small @click="goBack()">
        <v-icon>{{ $vuetify.icons.values.goBack }}</v-icon>
      </v-btn>

      <span class="ml-3">{{ selectedLayer.name }}</span>
    </div>

    <div class="mx-6">
      <v-checkbox v-model="isLayerVisible" :disabled="!isAdmin" label="Отображать на интерактивной карте" />
    </div>

    <div class="d-flex align-center mx-3 font-weight-bold">
      <div>Версии слоя</div>

      <v-spacer />

      <v-btn icon @click="$refs.createLayerVersionDialog.initDialog()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>

    <layer-version-card
      v-for="layerVersion of layerVersions"
      :key="layerVersion.id"
      :value="layerVersion"
      :elevation="0"
    />

    <template v-if="isAdditionalLayer">
      <v-spacer />

      <v-sheet class="layer__controls d-flex align-content-stretch pa-5">
        <v-btn
          class="flex-grow-1"
          :disabled="!isAdmin || pendingTasks.deleteLayer"
          :loading="pendingTasks.deleteLayer"
          small
          depressed
          color="secondary"
          @click="handleDeleteLayer()"
        >
          Удалить
        </v-btn>

        <v-btn
          class="ml-3 flex-grow-1"
          :disabled="!isAdmin"
          small
          depressed
          color="secondary"
          @click="handleRenameLayer()"
        >
          Переименовать
        </v-btn>
      </v-sheet>
    </template>

    <create-layer-version-dialog ref="createLayerVersionDialog" />
  </div>
</template>

<script>
  import { call, get } from 'vuex-pathify'
  import { sortByProperty, sortingOrder } from 'geoportal/src/utils/func'
  import PendingTasksManagerMixin, { handlePendingTask } from 'geoportal/src/mixins/PendingTasksManagerMixin'
  import { AccountType, LayerType } from '@/enums/aquarius'
  import { AsidePanelState } from '@/views/LayersManagementView/layersManagement'
  import LayerVersionCard from './LayerVersionCard'
  import CreateLayerVersionDialog from './CreateLayerVersionDialog'

  export default {
    name: 'Layer',
    components: { CreateLayerVersionDialog, LayerVersionCard },
    mixins: [PendingTasksManagerMixin],

    computed: {
      ...get('layersManagement', {
        selectedLayer: 'selectedLayer',
        selectedLayerVersions: 'selectedLayerVersions'
      }),

      isAdmin() {
        return this.$auth.user.account_type === AccountType.ADMIN
      },

      isLayerVisible: {
        get() {
          return this.selectedLayer.visible
        },
        set(value) {
          this.updateLayer({
            id: this.selectedLayer.id,
            data: { visible: value }
          })
        }
      },

      isAdditionalLayer() {
        return this.selectedLayer.type === LayerType.ADDITIONAL
      },

      layerVersions() {
        return sortByProperty([...this.selectedLayerVersions], 'created_datetime', sortingOrder.descending)
      }
    },

    methods: {
      ...call('layersManagement', {
        setAsidePanelState: 'setAsidePanelState',
        updateLayer: 'updateLayer',
        deleteLayer: 'deleteLayer'
      }),

      goBack() {
        this.setAsidePanelState([AsidePanelState.LAYER_LIST])
      },

      @handlePendingTask('deleteLayer')
      async handleDeleteLayer() {
        await this.deleteLayer(this.selectedLayer.id)
      },

      handleRenameLayer() {
        this.$bus.emit('layersManagement/renameLayer', this.selectedLayer.id)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .layer {
    min-height: 100%;

    .v-sheet:not(:first-child) {
      margin-top: 4px;
    }

    .layer__controls {
      position: sticky;
      bottom: 0;
      box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.05);
    }
  }
</style>
