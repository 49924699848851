<template>
  <div class="layers-management d-flex flex-grow-1 fill-height">
    <map-container ref="map" class="layers-management__map flex-grow-1">
      <log-dialog />
    </map-container>

    <aside-panel />
  </div>
</template>

<script>
  import { call } from 'vuex-pathify'
  import MapContainer from './MapContainer'
  import LogDialog from './LogDialog'
  import AsidePanel from './AsidePanel/AsidePanel'

  export default {
    name: 'LayersManagementView',
    components: { MapContainer, LogDialog, AsidePanel },

    destroyed() {
      this.resetState()
    },

    methods: {
      ...call('layersManagement', {
        resetState: 'resetState'
      })
    }
  }
</script>

<style lang="scss" scoped>
  .layers-management {
    .layers-management__map {
      position: relative;

      &::v-deep {
        .ol-attribution {
          font-size: 0.75rem;
        }

        .v-btn {
          right: 12px;
          top: 20px;
          z-index: 1;
        }
      }
    }
  }
</style>
